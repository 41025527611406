var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Drawer', {
    attrs: {
      "title": _vm.title,
      "width": "610",
      "draggable": "",
      "mask-closable": _vm.type == '0'
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('div', {
    staticClass: "drawer-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.formValidate,
      "label-position": "top"
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "问题标题",
      "prop": "title"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "关键词（额外触发词 若问题中已包含可无需填写 多个以任意符号分隔）",
      "prop": "keywords"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 4,
      "clearable": ""
    },
    model: {
      value: _vm.form.keywords,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keywords", $$v);
      },
      expression: "form.keywords"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    staticClass: "form-editor",
    attrs: {
      "label": "回答",
      "prop": "content"
    }
  }, [_c('editor', {
    ref: "editor",
    attrs: {
      "height": "200"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "权重值（值越大越靠前，支持小数）",
      "prop": "sortOrder"
    }
  }, [_c('InputNumber', {
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.form.sortOrder,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sortOrder", $$v);
      },
      expression: "form.sortOrder"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "热门回答",
      "prop": "hot"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.form.hot,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hot", $$v);
      },
      expression: "form.hot"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("是")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("否")])]), _c('Tooltip', {
    staticStyle: {
      "display": "inline-block !important"
    },
    attrs: {
      "content": "开启后将在“猜你想问”中显示热门hot标记",
      "placement": "right",
      "transfer": "",
      "max-width": "280"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-help-circle",
      "size": "20",
      "color": "#c5c5c5"
    }
  })], 1)], 1)])], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "启用反馈（允许赞踩）",
      "prop": "evaluable"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.form.evaluable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "evaluable", $$v);
      },
      expression: "form.evaluable"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("开")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("关")])])], 1)], 1)], 1), _c('Row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.evaluable,
      expression: "form.evaluable"
    }],
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "点赞数",
      "prop": "good"
    }
  }, [_c('InputNumber', {
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.form.good,
      callback: function ($$v) {
        _vm.$set(_vm.form, "good", $$v);
      },
      expression: "form.good"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "踩数",
      "prop": "bad"
    }
  }, [_c('InputNumber', {
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.form.bad,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bad", $$v);
      },
      expression: "form.bad"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '0',
      expression: "type != '0'"
    }],
    staticClass: "drawer-footer br"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };